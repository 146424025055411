/** @jsx jsx */
import { jsx} from "theme-ui";
// import * as React from "react";
import { useState } from "react";
import loadable from "@loadable/component";
import Button from "./contact-button";
// import * as styles from "./contact-widget.module.css"

const Dialog = loadable(() => import("./contact-dialog"));

export default function Widget({ options }) {
  const [open, setOpen] = useState(false);

  const toggleChat = (e) => {
    setOpen(!open);
  };

  const closeChat = (e) => {
    setOpen(false);
  };

  return (
    <div
      // className={styles.root}
      className="contact-widget"
      sx={{
        // bg:`primary`,
        position: `fixed`,
        zIndex: `1000`,
        width: `100%`,
        height: `100%`,
        top: 0,
        left: 0,
        pointerEvents: `none`,
      }}
    >
      <Dialog open={open} closeChat={closeChat} options={options} />
      <Button open={open} onClick={toggleChat} />
    </div>
  );
}
