import * as React from "react";
import Widget from "./src/components/contact-widget";
import { ThemeUIProvider } from "theme-ui";
import theme from "./src/gatsby-plugin-theme-ui";

export const wrapRootElement = ({ element }, options) => (
  <>
    {element}
    <ThemeUIProvider theme={theme}>
      <Widget options={options} />
    </ThemeUIProvider>
  </>
);
